import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/susruta-logo.svg"

import "./header.css"

const Header = ({ siteTitle }) => (
  <header>
    <Logo width="200" height="200"/>
    <div>
      <h1>Susruta Hospital</h1>
      <p>Bhadraghat</p>
      <p>Near Alamganj Police Station</p>
      <p>Gulzarbagh</p>
      <p>Patna (Bihar) 800 007</p>
      <p>Telephone No. – <Link to="tel://99552-11339">99552-11339</Link></p>
      <p>
        <Link to="mailto:susrutahospitals@gmail.com">susrutahospitals@gmail.com</Link>
      </p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
